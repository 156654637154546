import { createApp } from 'vue'
import {createRouter, createWebHistory} from "vue-router"
import * as Sentry from "@sentry/vue"
import './style.css'
import App from './App.vue'
import Home from "./pages/Home.vue"
import { createAuth0 } from '@auth0/auth0-vue'
import config from './config'
import { createPinia } from 'pinia'

const pinia = createPinia()
const app = createApp(App)
const router = createRouter({
  routes: [
    { path: '/:path(.*)*', component: Home}
  ],
  history: createWebHistory(),
})

Sentry.init({
  app,
  dsn: "https://5bce878eddc07e1600099bdce2ea00a7@o4504457192210432.ingest.sentry.io/4506336544817152",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
});
app.use(router)
app.use(pinia)
app.use(
  createAuth0({
    domain: "rounded.eu.auth0.com",
    clientId: config.auth0ClientId,
    authorizationParams: {
      redirect_uri: config.widgetShopUrl,
      audience: "https://space01.development.rounded.com/api/"
    },
    useRefreshTokens: true,
    cacheLocation: "localstorage",
  })
)
app.mount('#app')
