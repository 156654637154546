const environment = import.meta.env
const config = {
  widgetVersion: environment.VITE_WIDGET_VERSION ?? 'dev',
  widgetCosmosUrl: environment.VITE_WIDGET_COSMOS_URL ?? 'https://cosmos-api.rounded.com',
  widgetSpaceUrl: environment.VITE_WIDGET_SPACE_URL ?? 'https://space.rounded.com',
  widgetCloudFunctionUrl: environment.VITE_WIDGET_CLOUDFUNCTION_URL ?? 'https://europe-west1-capture-e9ff7.cloudfunctions.net',
  widgetLicenseKey: environment.VITE_WIDGET_LICENSEKEY ?? 'test',
  auth0ClientId: environment.VITE_AUTH0_CLIENTID ?? 'test',
  widgetShopApiUrl: environment.VITE_WIDGET_SHOP_API_URL ?? 'https://shop-api.rounded.com',
  widgetShopUrl: environment.VITE_WIDGET_SHOP_URL ?? 'https://shop.rounded.com',
  widgetFirebaseConfig: {
    apiKey: environment.VITE_FIREBASE_API_KEY ?? "",
    authDomain: environment.VITE_FIREBASE_AUTH_DOMAIN ?? "",
    databaseURL: environment.VITE_FIREBASE_DATABASE_URL ?? "",
    projectId: environment.VITE_FIREBASE_PROJECT_ID ?? "",
    storageBucket: environment.VITE_FIREBASE_STORAGE_BUCKET ?? "",
    messagingSenderId: environment.VITE_FIREBASE_MESSAGING_SENDER_ID ?? "",
    appId: environment.VITE_FIREBASE_APP_ID ?? "",
  },
}
export default config
