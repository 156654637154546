<script setup lang="ts">
import config from './../config.ts'
import { useAuthenticationStore } from './../store/authentication.ts'
import {useRoute} from "vue-router"

const route = useRoute()
const authStore = useAuthenticationStore()

/**
 * A release might be:
 *   - dev
 *   - v1.0-beta
 *   - v1.0
 */
async function loadWidget(release: string) {
  console.log('Loading widget', release)

  const link = document.createElement('link')
  link.rel = 'stylesheet'
  link.href = `https://widgets.rounded.com/${release}/shop/style.css`
  link.addEventListener('load', () => {
    const script = document.createElement('script')
    script.src = `https://widgets.rounded.com/${release}/shop/widget.js`
    script.addEventListener('load', async () => {
      (window as (typeof window & {rounded: any})).rounded.openShop({
        global: {
          licenseKey: config.widgetLicenseKey,
          globalDatabase: true,
          html5HistoryMode: true,
          events: {
            showFlyInHistory: () => console.log('showFlyInhistory shop'),
          },
          user: authStore.getUser(),
          userToken: await authStore.getToken(),
        },
        endpoint: {
          cosmos: config.widgetCosmosUrl,
          space: config.widgetSpaceUrl,
          shop: config.widgetShopApiUrl,
          cloudFunctions: config.widgetCloudFunctionUrl,
          firebase: config.widgetFirebaseConfig
        },
        topbar: {
          showDropdownInTitle: true,
          hideCloseButton: true,
          loginClick: async () => {
            await authStore.login()
          },
          logoutClick: async () => {
            await authStore.logout()
          },
        },
        price: {
          showPrices: true,
          country: 'nl',
          includeTax: true,
        },
      })
    })
    document.body.appendChild(script)
  })
  
  document.body.appendChild(link)
}

loadWidget(config.widgetVersion).then(async () => {
  if (route.query.authorized === 'true' && !authStore.isAuthenticated() && !authStore.auth0.isLoading) {
    await authStore.login()
  }
})
</script>

<template>
  <!-- Loading -->
  <div class="h-[7.5rem] bg-slate-100 flex items-center justify-center animate-pulse">
    <div class="w-64 sm:w-96 h-8 bg-slate-200 rounded-full animate-pulse"></div>
  </div>
  <div id="widget"></div>
</template>