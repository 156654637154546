import {defineStore} from 'pinia'
import {useAuth0} from '@auth0/auth0-vue'
import config from '../config'

export const useAuthenticationStore = defineStore('authentication', {
  state:() => {
    return {
      auth0: useAuth0()
    }
  },
  actions: {
    isAuthenticated(): boolean {
      return this.auth0.isAuthenticated ?? false
    },
    async getToken(): Promise<string|undefined> {
      try {
        return await this.auth0.getAccessTokenSilently()
      } catch (e) {
        console.warn('login cancelled', e)
        const err = e as {error?: string}
        if (err.error?.includes('consent_required')) {
          await this.login()
        }

        return undefined
      }
    },
    async login() {
      await this.auth0.loginWithRedirect()
    },
    async logout() {
      await this.auth0.logout({logoutParams: {returnTo: config.widgetShopUrl}})
    },
    getUser() {
      return this.auth0.user
    }
  }
})
