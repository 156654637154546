<script setup lang="ts">
import { useAuthenticationStore } from './store/authentication'

const authStore = useAuthenticationStore()
</script>

<template>
  <nav>
  </nav>
  <main v-if="!authStore.auth0.isLoading">
    <router-view />
  </main>
</template>
